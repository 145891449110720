import { createTheme } from '@mui/material/styles';

export const globalTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'heroSearch' && {
            '.MuiAutocomplete-inputRoot': {
              padding: '14.5px 65px 14.5px 25px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#C8CCCF',
              borderRadius: '100px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: '#768394',
              },
            }
          }),
        }),
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined-dark' },
          style: {
            padding: '12px 20px',
            border: '2px solid #2D3945',
            borderRadius: '8px',
            backgroundColor: '#FFF',
            fontFamily: 'TT Hoves',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2D3945',
            textTransform: 'none',
            '&:hover': {
              border: '2px solid #2D3945',
              backgroundColor: '#F8F8F8',
              color: '#2D3945',
            },
            '&:active': {
              border: '2px solid #2D3945',
              backgroundColor: '#ECECEC',
              color: '#2D3945',
            }
          }
        }
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            padding: '12px 20px',
            border: '2px solid #FFF',
            borderRadius: '8px',
            fontFamily: 'TT Hoves',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFF',
            textTransform: 'none',
            '&:hover': {
              border: '2px solid #2D3945',
              backgroundColor: '#F8F8F8',
              color: '#2D3945',
            },
            '&:active': {
              border: '2px solid #2D3945',
              backgroundColor: '#ECECEC',
              color: '#2D3945',
            }
          }),
          ...(ownerState.variant === 'contained' && {
            padding: '12px 20px',
            border: '2px solid #FF7001',
            borderRadius: '8px',
            fontFamily: 'TT Hoves',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFF',
            textTransform: 'none',
            boxShadow: 'unset',
            '&:hover': {
              border: '2px solid #E86500',
              backgroundColor: '#E86500',
              boxShadow: 'unset',
            },
            '&:active': {
              border: '2px solid #CC5900',
              backgroundColor: '#CC5900',
              boxShadow: 'unset',
            }
          }),
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'getLocation' && {
            position: 'absolute',
            zIndex: 2,
            right: '12px',
            top: '50%',
            transform: 'translateY(-50%)',
            minWidth: '48px',
            height: '48px',
            padding: 0,
            borderRadius: '50%',
            backgroundColor: '#FF7001',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#E86500',
            },
          }),
          ...(ownerState.className === 'getLocationSmall' && {
            position: 'absolute',
            zIndex: 2,
            right: '12px',
            top: '50%',
            transform: 'translateY(-50%)',
            minWidth: '24px',
            height: '24px',
            padding: 0,
            borderRadius: '50%',
            backgroundColor: 'white',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'white',
            },
          })
        })
      }
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#FFF',
    },
    primary: {
      main: '#FF7001',
      light: '#E86500',
      dark: '#CC5900',
    },
    grey: {
      50: '#C8CCCF',
    },
    text: {
      primary: '#2D3945',
      // secondary: '#2D3945'
    }
  }
});