import SuperTokens from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";

SuperTokens.init({
    appInfo: {
        appName: "Wildr",
        apiDomain: process.env.REACT_APP_SUPERTOKENS_API_DOMAIN, //  "https://staging.wildr.co.uk" "http://127.0.0.1:8000"
        websiteDomain: process.env.REACT_APP_SUPERTOKENS_UI_ROOT_URL, // http://localhost:3000
        apiBasePath: "/api/v1/auth",
        websiteBasePath: "/auth"
    },
    recipeList: [
        Passwordless.init({
            contactMethod: "EMAIL",
            style: `
            [data-supertokens~=container] {
                margin-top:126px;
            }
            `
        }),
        Session.init()
    ]
});

export default SuperTokens;

