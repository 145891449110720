import React, { lazy, Suspense } from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { globalTheme } from './wildr/assets/themes/globalTheme';
import './config/supertokens';
import { SuperTokensWrapper } from "supertokens-auth-react";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

// Lazy load the apps
const WildrApp = lazy(() => import('./wildr/WildrApp'));
const TechZoomApp = lazy(() => import('./techzoom/TechZoomApp'));

const AppRouter = () => {
  const currentSite = process.env.REACT_APP_SITE || 'wildr';
  const isTechZoomDomain = currentSite === 'techzoom' || 
                           window.location.hostname.includes('techzoom.io');

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isTechZoomDomain ? <TechZoomApp className="techzoom-wrapper"/> : <WildrApp />}
    </Suspense>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={globalTheme}>
        <SuperTokensWrapper>
          <AppRouter/>
        </SuperTokensWrapper>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();